"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIG_HOSTNAME_PATTERN = exports.DEFAULT_IAM_SIGNING_REGION = exports.DEFAULT_APPSYNC_API_SERVICE = exports.DEFAULT_REST_IAM_SIGNING_SERVICE = void 0;
exports.DEFAULT_REST_IAM_SIGNING_SERVICE = 'execute-api';
exports.DEFAULT_APPSYNC_API_SERVICE = 'appsync-api';
exports.DEFAULT_IAM_SIGNING_REGION = 'us-east-1';
/**
 * The REST endpoints generated by API Gateway
 * @see {@link https://docs.aws.amazon.com/general/latest/gr/apigateway.html#apigateway_region_data_plane}
 */
exports.APIG_HOSTNAME_PATTERN = /^.+\.([a-z0-9-]+)\.([a-z0-9-]+)\.amazonaws\.com/;
